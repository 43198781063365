import React from 'react';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';

import { About, Community, Download } from './components';

import { community } from './data';

const MobileApp = () => (
  <div>
    <Section>
      <About />
    </Section>
    <Divider />
    {/* <Section>
      <Download />
    </Section> */}
    <Divider />
  </div>
);

export default MobileApp;
